import { useRef, useEffect, useState } from "react";
import ReactGA from 'react-ga4'

export const useScrollManager = (refs) => {
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0)

    let isScrolling = useRef(false)
    const sectionsRef = useRef(refs);
    const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    const scrollToSection = (section, sectionIndex) => {
        isScrolling.current = true

        window.scrollTo({
            top: section.offsetTop,
            behavior: 'smooth',
        });

        setCurrentSectionIndex(sectionIndex);
    };

    function preventDefault(e) {
        e.preventDefault();

        if (isScrolling.current) {
            return;
        }

        if (e.deltaY < 0 && currentSectionIndex > 0) {
            const newCurrentSectionIndex = currentSectionIndex - 1
            ReactGA.event({
                category: 'User',
                action: 'scroll',
                value: newCurrentSectionIndex
            })
            scrollToSection(sectionsRef.current[newCurrentSectionIndex].current, newCurrentSectionIndex)
        } else if (e.deltaY > 0 && currentSectionIndex + 1 < sectionsRef.current.length) {
            const newCurrentSectionIndex = currentSectionIndex + 1
            ReactGA.event({
                category: 'User',
                action: 'scroll',
                value: newCurrentSectionIndex
            })
            scrollToSection(sectionsRef.current[newCurrentSectionIndex].current, newCurrentSectionIndex)
        }
    }

    function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
            preventDefault(e);
            return false;
        }
    }

    useEffect(() => {
        window.addEventListener('DOMMouseScroll', preventDefault, { passive: false }); // older FF
        window.addEventListener('wheel', preventDefault, { passive: false }); // modern desktop
        window.addEventListener('mousewheel', preventDefault, { passive: false }); // modern desktop
        window.addEventListener('touchmove', preventDefault, { passive: false }); // mobile
        window.addEventListener('keydown', preventDefaultForScrollKeys, { passive: false });

        return () => {
            window.removeEventListener('DOMMouseScroll', preventDefault)
            window.removeEventListener('wheel', preventDefault); // modern desktop
            window.removeEventListener('mousewheel', preventDefault); // modern desktop
            window.removeEventListener('touchmove', preventDefault); // mobile
            window.removeEventListener('keydown', preventDefaultForScrollKeys);

        }
    }, [currentSectionIndex])

    useEffect(() => {
        if (isScrolling.current) {
            setTimeout(() => {
                isScrolling.current = false
            }, 1000)
        }
    }, [currentSectionIndex])

    return { currentSectionIndex, scrollToSection };
};

