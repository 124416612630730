import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Point } from "../redundant/Point";
import './Offer.css'

const Offer = React.forwardRef((props, ref) => {
    return (
        <div className="section our-offer" ref={ref}>
            <Container>
                <Row>
                    <Col md="auto">
                        <h2>
                            Nasza oferta
                        </h2>
                        <p>
                            Kierujemy ją przede wszystkim do właścicieli restauracji,
                            którzy chcą by ich goście zawsze wychodzili zadowoleni z szybkości i jakości obsługi.
                        </p>
                    </Col>
                </Row>
                <Row className="our-offer-content">
                    <Col md={12} lg={5}>
                        <video controls>
                            <source src="./video.mp4"></source>
                        </video>
                    </Col>
                    <Col md={12} lg={{span: 6, offset: 1}} className="points"> 
                        <Point number={1}>
                            Montujemy urządzenie przy stoliku
                        </Point>
                        <Point number={2}>
                            Klient naciska przycisk gdy jest gotowy
                        </Point>
                        <Point number={3}>
                            Kelnerowi na urządzeniu wyświetla się numer stolika
                        </Point>
                    </Col>
                </Row>
            </Container>
        </div>
    )
})

export { Offer };