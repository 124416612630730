import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./Form.css"
import axios from 'axios'

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export const FormSection = React.forwardRef((props, ref) => {

    const [email, setEmail] = useState('')
    const [content, setContent] = useState('')
    const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false)
    const [triggerEmailError, setTriggerEmailError] = useState(false)
    const [triggerCheckboxError, setTriggerCheckboxError] = useState(false)

    const termsAndConditions = `
        Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą
        o ochronie danych osobowych w związku z wysłaniem zapytania przez
        formularz kontaktowy. Zostałem poinformowany że przysługuje mi prawo
        dostępu do swoich danych, możliwości ich poprawienia, żądania
        zaprzestania ich przetwarzania. Administratorem danych osobowych
        jest Teach Tech Service Marcin Warzybok (ul. Bieżanowska 258B, 30-856 Kraków)

        `

    const sendEmail = async () => {
        let isValidationError = false

        console.log(emailRegex.test(email))
        if (!emailRegex.test(email)) {
            setTriggerEmailError(true)
            isValidationError = true
        } else {
            setTriggerEmailError(false)
        }

        if (!isTermsAndConditionsChecked) {
            setTriggerCheckboxError(true)
            isValidationError = true
        } else {
            setTriggerCheckboxError(false)
        }

        if (isValidationError) {
            return;
        }

        setTriggerEmailError(false)
        setTriggerCheckboxError(false)
        try {
            await axios.post('https://api.pressmenow.com/contact-form', {
                email,
                content,
            })
        } catch (err) {
            console.log(err)
        }


        setEmail('')
        setContent('')

    }

    return (
        <div className="section form" ref={ref}>
            <Container>
                <Row>
                    <Col>
                        <h2>Kontakt</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 8, offset: 2 }}>
                        <Form>
                            <Form.Group onChange={(e) => e.target.value} className="mb-3" controlId="formBasicEmail">
                                <Form.Label className={triggerEmailError ? "error-label" : ""}>Podaj proszę email, abyśmy mogli się z tobą skontaktować</Form.Label>
                                <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Wprowadź proszę email" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Napisz proszę jakie masz pytania, a rozwiejemy twoje wątpliwości</Form.Label>
                                <Form.Control value={content} onChange={(e) => setContent(e.target.value)} as="textarea" aria-label="With textarea" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check
                                    className={triggerCheckboxError ? "error-label" : ""}
                                    checked={isTermsAndConditionsChecked}
                                    onChange={setIsTermsAndConditionsChecked}
                                    type="checkbox"
                                    label={termsAndConditions}
                                />
                            </Form.Group>
                            <Button
                                size="lg"
                                className="button-awarded submit"
                                onClick={sendEmail}
                            >
                                Wyślij
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    )
})