import "./Point.css"

export function Point(props) {
    return (
        <div className="point">
            <span>{props.number}.</span>
            <p>
                {props.children}
            </p>
        </div>
    )
}