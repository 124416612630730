import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useRef, useState } from 'react';
import './App.css';
import { useScrollManager } from './redundant/useScrollManager';
import { CallToAction } from './sections/CallToAction';
import { FormSection } from './sections/Form';
import { Header } from './sections/Header';
import { Offer } from './sections/Offer';
import ReactGa from 'react-ga4'


const TRACKING_ID = 'G-49M0GEDZ7S'
ReactGa.initialize(TRACKING_ID)

function App() {
  const headerRef = useRef()
  const callToActionRef = useRef()
  const offerRef = useRef()
  const formRef = useRef()
  const { scrollToSection } = useScrollManager([headerRef, callToActionRef, offerRef, formRef])

  useEffect(() => {
    ReactGa.send({
      hitType: 'pageview',
      page: "/"
    })
  }, [])

  return (
    <div className="App">
      <Header ref={headerRef} />
      <CallToAction ref={callToActionRef} scrollToOfferSection={() => scrollToSection(offerRef.current, 2)} />
      <Offer ref={offerRef} />
      <FormSection ref={formRef} />
    </div>
  );
}

export default App;

