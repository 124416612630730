import React from "react";
import { Container } from "react-bootstrap";

const Header = React.forwardRef((props, ref) => {
  return <div className="section header" ref={ref}>
    <Container>
      <h1 className='animated-white-text'>
        Siedzisz przy stolik w restauracji <br />
        ze znajomymi i słyszysz:
      </h1>
      <ul className='animated-white-text'>
        <li>
          <span className='awarded animated-awarded-text'>Ja********</span>, gdzie jest ten kelner ? Ile można czekać ?
        </li>
        <li>Jeszcze dwie minuty i wychodzę</li>
        <li>Czy mogę przyjąć od państwa zamówienie ?</li>
        <li>Jeszcze nie, proszę nam dać 5 minut</li>
      </ul>
    </Container>
  </div>;
})

export { Header };