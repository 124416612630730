import React, { useState } from "react";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import "./CallToAction.css"
import ReactGA from 'react-ga4'

const CallToAction = React.forwardRef(({ scrollToOfferSection }, ref) => {
    const [isCloseTabModalOpen, setIsCloseTabModalOpen] = useState(false)

    const handleOneTimeButton = () => {
        ReactGA.event({
            category: 'Call to action',
            action: 'one time',
        })
    }

    const handleMoreButton = () => {
        ReactGA.event({
            category: 'Call to action',
            action: 'more times'
        })
        scrollToOfferSection();
    }

    const openCloseTabModal = () => {
        setIsCloseTabModalOpen(true)
        ReactGA.event({
            category: 'Call to action',
            action: 'never happens'
        })
    }

    const closeModal = () => {
        setIsCloseTabModalOpen(false);
        setTimeout(() => {
            scrollToOfferSection()
        }, 500)
    }

    return (
        <div className="section call-to-action" ref={ref}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <h2>Ile razy przydarzyła Ci się taka lub podobna sytuacja ?</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="call-to-action-form">
                        <Row>
                            <Col xs={12} sm={4}>
                                <Button
                                    onClick={openCloseTabModal}
                                    size="lg"
                                    className="button-empty"
                                >
                                    Nigdy
                                </Button>
                            </Col>
                            <Col xs={12} sm={4}>
                                <Button onClick={handleOneTimeButton} size="lg" className="button-empty">
                                    1
                                </Button>
                            </Col>
                            <Col xs={12} sm={4}>
                                <Button onClick={handleMoreButton} size="lg" className="button-awarded">
                                    Więcej
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>

            <Modal
                show={isCloseTabModalOpen}
                size="lg"
                className="modal-close-tab"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Dzięki za zainteresowanie
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Jesteś człowiekiem niezwykle cierpliwym, ta oferta nie będzie dla Ciebie.<br></br>
                        Możesz zamknąć tę stronę lub zostać
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button-awarded" onClick={closeModal}>Zostań</Button>
                </Modal.Footer>
            </Modal>

            <div>
                {/* <p>Dzięki, jesteś człowiekiem cierpliwym i bezproblemowym, możesz teraz wyjść</p>
                <p>A co jeśli dałbyś nam znać, w którym momencie jesteś gotowy, jednym prostym kliknięciem ?</p> */}
            </div>
        </div>
    )
})

export { CallToAction };